
import { defineComponent } from 'vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default defineComponent({
  name: 'App',
  components: {
    AppSidebar
  }
})
